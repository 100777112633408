import React from "react";
import styled from "styled-components";
import Page from "../components/Page";
import { pages } from "../utils/pages";
import Header from "../components/Header";

const MainPage = () => {
  return (
    <WhiteBackground>
      <Header className="header" />
      <Page page={pages[0]} />
      <Page page={pages[1]} />
      <Page page={pages[2]} />
    </WhiteBackground>
  );
};

const WhiteBackground = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default MainPage;
