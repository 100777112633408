import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { widthBrakePoints } from "../utils/pages";

const Header = ({ className }) => {
  return (
    <Container className={className}>
      <Tab target="_blank" to={"privacyPolicy"}>
        Privacy policy
      </Tab>
      <Tab target="_blank" to={"termsOfUse"}>
        Terms of use
      </Tab>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-block-end: 17rem;
  width: 50%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgb(255, 255, 255);

  @media (min-width: ${widthBrakePoints.Mobile}) {
    padding-top: 45px;
    padding-left: 140px;
  }
  @media (max-width: ${widthBrakePoints.Mobile}) {
    padding-left: 16px;
    padding-top: 12px;
    width: 100%;
  }
`;

const Tab = styled(Link)`
  font-size: 2.7rem;
  font-weight: 400;
  text-decoration: none;
  color: rgba(47, 17, 80, 1);
  margin-inline-end: 6.7rem;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    margin-inline-end: 3.1rem;
    font-size: 1.2rem;
  }
`;

export default Header;
