import React from "react";
import styled from "styled-components";
import IosImage from "../assets/img/iosImage.png";
import AndroidImage from "../assets/img/androidImage.png";
import { UAParser } from "ua-parser-js";
import { widthBrakePoints } from "../utils/pages";

const ButtonsContainer = () => {
  const { name } = UAParser().os;
  return (
    <Container>
      {name !== "Android" && <Button src={IosImage} />}
      {name !== "iOS" && <Button src={AndroidImage} />}
    </Container>
  );
};

const Container = styled.div`
  height: 10rem;
  position: absolute;
  bottom: 0;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    height: 4rem;
    position: relative;
    top: 4.4rem;
  }
`;
const Button = styled.img`
  max-width: 17.3rem;
  height: 5.1rem;
  margin-inline-end: 2.5rem;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    height: 4rem;
  }
`;
export default ButtonsContainer;
