import React from "react";
import styled from "styled-components";
import Logo from "../assets/svg/whiteLogo.svg";
import InfoPrivacyPage from "../components/InfoPrivacyPage";
import { widthBrakePoints } from "../utils/pages";
import "../style/privacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <Container>
      <PurpleBar>
        <WhiteLogo src={Logo} />
      </PurpleBar>
      <InfoPrivacyPage />
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    flex-direction: column;
  }
`;

const PurpleBar = styled.div`
  height: 100%;
  width: 26rem;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  background: radial-gradient(420.1% 100% at 50% 0%, #8c39af 0%, #2e1150 100%);
  padding-top: 3.8rem;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    height: 6.2rem;
    width: 100%;
    justify-content: flex-start;
    padding: 2.8rem 0 0 1.6rem;
  }
`;

const WhiteLogo = styled.img`
  @media (max-width: ${widthBrakePoints.Mobile}) {
    width: 8rem;
  }
`;

export default PrivacyPolicy;
