import { Route, Routes } from "react-router";
import MainPage from "./pages/MainPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse.jsx";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/termsOfUse" element={<TermsOfUse />} />
    </Routes>
  );
}

export default App;
