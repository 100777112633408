import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { widthBrakePoints } from "../utils/pages";

const ImagesContainer = ({ images, imageStyle, className }) => {
  return (
    <Container className={className}>
      {images.map((image, index) => {
        return (
          <Image
            key={index + image}
            src={image}
            imageStyle={imageStyle[index]}
          />
        );
      })}
    </Container>
  );
};
const Container = styled.div`
  position: absolute;
  height: 100rem;
  width: 80rem;
  left: 47%;
  top: 4.1rem;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    position: relative;
    width: 100vw;
    height: 40vh;
    left: 0;
    bottom: 0;
  }
`;

const Image = styled.img`
  position: absolute;
  ${({ imageStyle }) =>
    css`
      transform: rotate(${imageStyle.rotation}deg);
      top: ${imageStyle.top};
      left: ${imageStyle.left};
      max-height: ${imageStyle.height}vh;
      max-width: 55vw;
      object-fit: cover;
    `}
`;

export default ImagesContainer;
