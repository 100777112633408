import React from "react";
import styled from "styled-components";
import LogoSvg from "../assets/svg/logo.svg";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { widthBrakePoints } from "../utils/pages";

const InfoMainPage = ({ text, keywords, textWidth, textWidthMobile }) => {
  const { width } = useWindowDimensions();

  const makeBold = (item) => {
    let newText = item;
    keywords.forEach((keyword) => {
      const re = new RegExp(keyword, "g");
      newText = newText.replace(re, "<span class=bold>" + keyword + "</span>");
    });
    return newText;
  };

  return (
    <Container>
      <Logo src={LogoSvg} />
      <LightText textWidth={width > 640 ? textWidth : textWidthMobile}>
        <div dangerouslySetInnerHTML={{ __html: makeBold(text) }} />
      </LightText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 22.4rem;
  height: 5.5rem;
  margin-block-end: 6rem;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    width: 9.1rem;
    margin-block-end: 2.3rem;
  }
`;

const LightText = styled.span`
  width: ${({ textWidth }) => textWidth};
  font-weight: 300;
  line-height: 8.9rem;
  font-size: 7.4rem;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    font-size: 3.6rem;
    line-height: 3.4rem;
  }
`;

export default InfoMainPage;
