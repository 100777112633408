import Image1 from "../assets/img/image1.png";
import Image2 from "../assets/img/image2.png";
import Image3 from "../assets/img/image3.png";
import Image4 from "../assets/img/image4.png";
import Image5 from "../assets/img/image5.png";
import Image6 from "../assets/img/image6.png";
import Image7 from "../assets/img/image7.png";
import Image8 from "../assets/img/image8.png";
import Image9 from "../assets/img/image9.png";

export const widthBrakePoints = { Mobile: "940px" };

export const pages = [
  {
    text: "Join us to explore your own photos",
    textWidth: "74rem",
    textWidthMobile: "32.1rem",
    keywords: ["explore", "photos"],
    images: [Image1, Image2, Image3],
    imageStyleDesktop: [
      {
        rotation: "-10.52",
        top: "5%",
        left: "14%",
        height: "41",
      },
      {
        rotation: "10.92",
        top: "18%",
        left: "55%",
        height: "37",
      },
      { rotation: "5.61", top: "41%", left: "24%", height: "42" },
    ],
    imageStyleMobile: [
      {
        rotation: "-32.52",
        top: "-3%",
        left: "-8%",
        height: "35",
      },
      {
        rotation: "19.92",
        top: "-40%",
        left: "55%",
        height: "33",
      },
      { rotation: "4.61", top: "18%", left: "32%", height: "35" },
    ],
  },
  {
    text: `Find out new different ways to see your \<br/> old photos`,
    textWidth: "71rem",
    textWidthMobile: "31.1rem",
    keywords: ["different", "ways"],
    images: [Image4, Image5, Image6],
    imageStyleDesktop: [
      { rotation: "-10.52", top: "44%", left: "51%", height: "35" },
      { rotation: "12.92", top: "10%", left: "58%", height: "36" },
      { rotation: "-12.61", top: "16%", left: "14%", height: "41" },
    ],
    imageStyleMobile: [
      { rotation: "16.52", top: "37%", left: "52%", height: "29" },
      { rotation: "12.92", top: "-38%", left: "45%", height: "34" },
      { rotation: "-12.61", top: "0%", left: "-2%", height: "37" },
    ],
  },
  {
    text: "Have fun with your friends and family",
    textWidth: "60.9rem",
    textWidthMobile: "35.1rem",
    keywords: ["Have", "fun"],
    images: [Image7, Image8, Image9],
    imageStyleDesktop: [
      { rotation: "-5.52", top: "2%", left: "46%", height: "39" },
      { rotation: "12.92", top: "16%", left: "19%", height: "41" },
      { rotation: "-9.61", top: "39%", left: "47%", height: "41" },
    ],
    imageStyleMobile: [
      {
        rotation: "-25.52",
        top: "-58%",
        left: "52%",
        height: "32",
      },
      {
        rotation: "10.92",
        top: "-16%",
        left: "5%",
        height: "35",
      },
      { rotation: "-9.61", top: "25%", left: "44%", height: "36" },
    ],
  },
];
