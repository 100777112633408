import React from "react";
import styled from "styled-components";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { widthBrakePoints } from "../utils/pages";
import ButtonsContainer from "./ButtonsContainer";
import ImagesContainer from "./ImagesContainer";
import InfoMainPage from "./InfoMainPage";

const Page = ({ page }) => {
  const { width } = useWindowDimensions();
  const ref = React.useRef(null);
  const [isIntersecting, setIsIntersecting] = React.useState(false);
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsIntersecting(entry.isIntersecting);
        });
      },
      { root: document.querySelector(".whiteBackground"), threshold: 0.5 }
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Container ref={ref} IsIntersecting={isIntersecting}>
      <LeftSide className={isIntersecting ? "fade-in" : "fade-out"}>
        <InfoMainPage
          text={page.text}
          textWidth={page.textWidth}
          textWidthMobile={page.textWidthMobile}
          keywords={page.keywords}
        />
        <ButtonsContainer />
      </LeftSide>
      <PurpleBackground>
        <ImagesContainer
          images={page.images}
          imageStyle={
            width > 640 ? page.imageStyleDesktop : page.imageStyleMobile
          }
          className={isIntersecting ? "fade-in" : "fade-out"}
        />
      </PurpleBackground>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  scroll-snap-align: start;
  overflow: hidden;
  @media (min-width: ${widthBrakePoints.Mobile}) {
    align-items: center;
  }

  @media (max-width: ${widthBrakePoints.Mobile}) {
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding-top: 24px;
  }

  & .fade-in,
  & .fade-out {
    transition: opacity 0.3s linear;
  }

  & .fade-out {
    opacity: 0;
  }

  & .fade-in {
    opacity: 1;
  }
`;

const LeftSide = styled.div`
  padding: 3rem 0 0 13.9rem;
  width: 73%;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    padding: 1.6rem;
    height: 50vh;
  }
`;

const PurpleBackground = styled.div`
  background: radial-gradient(circle at 45% 45%, #8c39af 0%, #2e1150 59%);
  height: 100%;
  width: 27%;
  @media (max-width: ${widthBrakePoints.Mobile}) {
    height: 50vh;
    width: 100vw;
  }
`;
export default Page;
